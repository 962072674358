import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CMCalendarApp.css";

const secondsInDay = 60 * 60 * 24;
// const firstDate = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);
const firstDate = new Date(2025, 0, 1, 0, 0, 0, 0);
// const parentPattern = [
//   "M",
//   "M",
//   "F",
//   "F",
//   "M",
//   "M",
//   "M",
//   "F",
//   "F",
//   "M",
//   "M",
//   "F",
//   "F",
//   "F",
// ];
const parentPattern = [
  "F",
  "M",
  "M",
  "W",
  "W",
  "F",
  "F",
  "M",
  "M",
  "M",
  "W",
  "W",
  "F",
  "F",
];
const parentColorMatch = {
  M: "cm-calendar-blue",
  F: "cm-calendar-pink",
  W: "cm-calendar-grey",
};

function fetchTileClassName(data) {
  const date = data.date;
  const today = new Date();

  const deltaTimeSeconds =
    (date.getTime() - firstDate.getTime()) * 0.001 +
    (firstDate.getTimezoneOffset() - date.getTimezoneOffset()) * 60;

  const deltaDays = deltaTimeSeconds / secondsInDay;
  const parent = parentPattern[deltaDays % parentPattern.length];

  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  const formatToday = isToday
    ? " font-bold italic text-purple-600 text-xl underline"
    : "";

  return parentColorMatch[parent] + formatToday;
}

function CMCalendarApp() {
  const [value, setValue] = useState(new Date());

  return (
    <div className="m-4 flex flex-col grid justify-items-center">
      <div>Chi lo va a prendere all'uscita dalla scuola?</div>
      <br />
      <Calendar
        onChange={setValue}
        value={value}
        tileClassName={fetchTileClassName}
        showDoubleView={false}
        showNeighboringMonth={false}
        showFixedNumberOfWeeks={false}
      />
    </div>
  );
}

export default CMCalendarApp;
